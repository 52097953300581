import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { addData } from '../Servicescalls';

function Termsandconditions() {
    const [policy, setPolicy] = useState([])
    const getAllpolicy = async () => {
        const resonse = await addData("getPolicies")
        var _data = resonse
        setPolicy(_data?.data?.policies)
    }

    useEffect(() => {
        getAllpolicy()
    }, [])

    return (
        <div >
            <div id="page" className="hfeed site">
                <Header />
                <div data-elementor-type="wp-page" data-elementor-id={51} className="elementor elementor-51">
                    <section style={{
                        backgroundImage: `linear-gradient(#0000005e, #000000a6), url("https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/unsplash-qQ2o7JXiCLc.jpg")`, padding: "150px 100px 100px 100px"
                    }}
                        className="text-center elementor-section elementor-top-section elementor-element elementor-element-87313cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="87313cf" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-container elementor-column-gap-default">
                            <div style={{ width: "100%" }} className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9181f1e" data-id="9181f1e" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d85c4d7 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="d85c4d7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-e2a12ad elementor-widget elementor-widget-heading" data-id="e2a12ad" data-element_type="widget" data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 style={{ fontSize: "50px", color: "#fff" }} className="elementor-heading-title elementor-size-default">TERMS AND CONDITIONS</h3>
                                                    </div>
                                                </div>
                                                <div className="text-center elementor-element elementor-element-c7be932 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="c7be932" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="icon-list.default">
                                                    <div className="elementor-widget-container">
                                                        <ul className="elementor-icon-list-items elementor-inline-items">
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-text">HOME</span>
                                                            </li>
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-icon">
                                                                    <i aria-hidden="true" className="jki jki-slash-solid" /> </span>
                                                                <span className="elementor-icon-list-text">
                                                                    TERMS AND CONDITIONS</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>

                    <section style={{ background: "#fff", paddingTop: "50px" }} className="elementor-section elementor-top-section elementor-element elementor-element-d4f5295 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4f5295" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div style={{ background: "#fff" }} className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-edb1f35" data-id="edb1f35" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-9d36a98 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="9d36a98" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-heading  align-left align-tablet- align-mobile- jeg_module_51_13_65d83d3d34a8d">
                                                <h3 className="heading-section-subtitle  style-color">
                                                    TERMS AND CONDITIONS</h3>

                                                <div style={{ color: "#000", maxWidth: "100%" }} className="heading-section-description">
                                                    <div
                                                        className="mt-4"
                                                        dangerouslySetInnerHTML={{
                                                            __html: policy.termsCondition,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>


                </div>
                <Footer />

            </div>
        </div>

    )
}

export default Termsandconditions