import React, { useEffect, useState } from 'react'
import { addData } from '../Servicescalls'

function Commonservice() {

    const [services, setservices] = useState([])
    const getAllservieces = async () => {
        const resonse = await addData("getServices")
        var _data = resonse
        setservices(_data?.data?.data)
    }

    useEffect(() => {
        getAllservieces()
    }, [])

    return (

        <section className="elementor-section elementor-inner-section elementor-element elementor-element-3beccc3 elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3beccc3" data-element_type="section">
            <div className="elementor-container row">

                {services.map((data, index) => (
                    <div key={index} data-aos="fade-up"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine" style={{padding:"0px"}} className="mt-3 elementor-column col-md-4 elementor-inner-column elementor-element elementor-element-4a0c441 elementor-invisible" data-id="4a0c441" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                        <div style={{ border: "1px solid #000000ad" }} className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-dd27c98 jkit-equal-height-disable elementor-widget elementor-widget-jkit_icon_box" data-id="dd27c98" data-element_type="widget" data-widget_type="jkit_icon_box.default">
                                <div className="elementor-widget-container">
                                    <div className="jeg-elementor-kit jkit-icon-box icon-position- elementor-animation-float jeg_module_51_15_65d83d3d45ae8">
                                        <div className="jkit-icon-box-wrapper hover-from-bottom">
                                            {/* <div className="icon-box icon-box-header elementor-animation-">
                  <div className="icon style-color"><i aria-hidden="true" className="jki jki-diamond-light" /></div>
              </div> */}
                                            <div className="icon-box icon-box-body">
                                                <h3 className="title text-center mb-3">{data.title}</h3>
                                                <div className='text-center mb-3'>
                                                    <del>₹ {data.price} </del>
                                                    <span className='title'> ₹ {data.offerPrice} </span>
                                                    {/* <span style={{ color: "#000000ad", fontSize: "20px" }} className='title'> / {data.duration} </span> */}

                                                </div>
                                                <p className="icon-box-description">
                                                    {data.description}
                                                </p>

                                                <ul className="elementor-icon-list-items mb-3">
                                                    {data?.points.map((data1, key) => (
                                                        <li key={key} className="elementor-icon-list-item icon-box-description">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fa fa-check-square-o text-dark" /> </span>
                                                            <span className="elementor-icon-list-text mt-1">{data1.value}</span>
                                                        </li>
                                                    ))}


                                                </ul>

                                                <div className="icon-box-button ">
                                                    <div className="btn-wrapper icon-position-after">
                                                        <a type="button" data-toggle="modal" data-target="#exampleModal" className="icon-box-link btn btn-outline-secondary w-50 p-2">Buy now<i aria-hidden="true" className="jki jki-right-arrow-light" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}


            </div>
        </section>
    )
}

export default Commonservice