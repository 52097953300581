import React from 'react'
import Header from './Header'
import Footer from './Footer'


function About() {
    return (
        <div >
            <div id="page" className="hfeed site">
                <Header />
                <div data-elementor-type="wp-page" data-elementor-id={51} className="elementor elementor-51">
                    <section style={{
                        backgroundImage: `linear-gradient(#0000005e, #000000a6), url("https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/unsplash-qQ2o7JXiCLc.jpg")`, padding: "150px 100px 100px 100px"
                    }}
                        className="text-center elementor-section elementor-top-section elementor-element elementor-element-87313cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="87313cf" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-container elementor-column-gap-default">
                            <div style={{ width: "100%" }} className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9181f1e" data-id="9181f1e" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d85c4d7 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="d85c4d7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-e2a12ad elementor-widget elementor-widget-heading" data-id="e2a12ad" data-element_type="widget" data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 style={{ fontSize: "50px", color: "#fff" }} className="elementor-heading-title elementor-size-default">ABOUT US</h3>
                                                    </div>
                                                </div>
                                                <div style={{ display: "none" }} className="elementor-element elementor-element-c7be932 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="c7be932" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="icon-list.default">
                                                    <div className="elementor-widget-container">
                                                        <ul className="elementor-icon-list-items elementor-inline-items">
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-text">HOME</span>
                                                            </li>
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-icon">
                                                                    <i aria-hidden="true" className="jki jki-slash-solid" /> </span>
                                                                <span className="elementor-icon-list-text">ABOUT US</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>

                    <section className="elementor-section elementor-top-section elementor-element elementor-element-c8d0629 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c8d0629" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8b8f7bc" data-id="8b8f7bc" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-057239f elementor-widget elementor-widget-spacer" data-id="057239f" data-element_type="widget" data-widget_type="spacer.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-spacer">
                                                <div className="elementor-spacer-inner" />
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-left"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine"
                                        className="elementor-element elementor-element-2bb2ef8 elementor-widget__width-auto elementor-invisible elementor-widget elementor-widget-image" data-id="2bb2ef8" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="image.default">
                                        <div className="elementor-widget-container">
                                            <img fetchpriority="high" decoding="async" width={800} height={534} src="wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-1024x683.jpg" className="attachment-large size-large wp-image-815" alt="" srcSet="https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-1024x683.jpg 1024w, https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-300x200.jpg 300w, https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-768x512.jpg 768w, https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-1536x1024.jpg 1536w, https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash-800x533.jpg 800w, https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/dan-burton-zckf8yaZl9o-unsplash.jpg 1920w" sizes="(max-width: 800px) 100vw, 800px" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8049198" data-id={8049198} data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-4f5d2d5 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="4f5d2d5" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-heading  align-left align-tablet- align-mobile- jeg_module_51_7_65d83d3d1751b">
                                                <h3 className="heading-section-subtitle  style-color">About Us</h3>
                                                <div className="heading-section-title  display-inline-block">
                                                    <h2 className="heading-title">WE OVER 20 YEARS EXPERIENCE</h2>
                                                </div>
                                                <div className="heading-section-description">
                                                    <p>
                                                        Bikers24 is India’s leading bike service provider just search best two wheeler service near me, spread into a number of cities and states providing you with 500+ best quality services for your bikes and scooty like repair, servicing, maintenance, inspection , and a lot more.
                                                    </p>
                                                    <p>
                                                        We are always concerned about the rebirth of your Bike or scooty, using all the best quality products and vehicle servicing equipment that are available at our service centers. To find us search best bike service near me .
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="elementor-element elementor-element-6a72001 elementor-widget__width-auto elementor-invisible elementor-widget elementor-widget-jkit_button" data-id="6a72001" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="jkit_button.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-button  icon-position-before jeg_module_51_8_65d83d3d18ae2">
                                                <a href="#" className="jkit-button-wrapper">ABOUT US</a></div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-ed43b9f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ed43b9f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-01176e8 elementor-invisible" data-id="01176e8" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInLeft&quot;}">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d547402 elementor-widget elementor-widget-jkit_heading" data-id="d547402" data-element_type="widget" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-heading  align-left align-tablet- align-mobile- jeg_module_51_28_65d83d3d924ba">
                                                <h3 className="heading-section-subtitle  style-color">Custom YZR700</h3>
                                                <div className="heading-section-title  display-inline-block">
                                                    <h2 className="heading-title">CAFE RACER</h2>
                                                </div>
                                                <div className="heading-section-description">
                                                    <p>
                                                        Get your motorcycle repair at two wheeler srvice center done at the nearest Bikers24 garage or at your doorstep and we are the best bike repair shop near you.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-bbc5fe9 elementor-widget__width-auto elementor-widget elementor-widget-jkit_button" data-id="bbc5fe9" data-element_type="widget" data-widget_type="jkit_button.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-button  icon-position-before jeg_module_51_29_65d83d3d93ba0">
                                                <a type="button" data-toggle="modal" data-target="#exampleModal" className="jkit-button-wrapper">LEARN MORE</a></div>
                                        </div>
                                    </div>
                                    {/* <div className="elementor-element elementor-element-9ebcf20 elementor-widget__width-auto elementor-widget elementor-widget-jkit_button" data-id="9ebcf20" data-element_type="widget" data-widget_type="jkit_button.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-button  icon-position-after jeg_module_51_30_65d83d3d951b7">
                                                <a href="#" className="jkit-button-wrapper">SHOW ALL BIKES<i aria-hidden="true" className="jki jki-right-arrow-light" /></a></div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bd6a382" data-id="bd6a382" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-dc08a96 elementor-invisible elementor-widget elementor-widget-jkit_client_logo" data-id="dc08a96" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="jkit_client_logo.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-client-logo arrow-bottom-middle jeg_module_51_31_65d83d3d99dde" data-id="jeg_module_51_31_65d83d3d99dde" data-settings="{&quot;autoplay&quot;:false,&quot;autoplay_speed&quot;:3500,&quot;autoplay_hover_pause&quot;:false,&quot;show_navigation&quot;:false,&quot;navigation_left&quot;:&quot;<i aria-hidden=\&quot;true\&quot; class=\&quot;fas fa-angle-left\&quot;><\/i>&quot;,&quot;navigation_right&quot;:&quot;<i aria-hidden=\&quot;true\&quot; class=\&quot;fas fa-angle-right\&quot;><\/i>&quot;,&quot;show_dots&quot;:false,&quot;arrow_position&quot;:&quot;bottom&quot;,&quot;responsive&quot;:{&quot;desktop&quot;:{&quot;items&quot;:3,&quot;margin&quot;:80,&quot;breakpoint&quot;:1025},&quot;tablet&quot;:{&quot;items&quot;:3,&quot;margin&quot;:80,&quot;breakpoint&quot;:768},&quot;mobile&quot;:{&quot;items&quot;:3,&quot;margin&quot;:80,&quot;breakpoint&quot;:0}}}">
                                                <div className="client-list">
                                                    <div className="client-track">
                                                        <div className="client-slider item ">
                                                            <div className="image-list">
                                                                <div className="content-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/vintage-skull-motorcycle-rider-logo-300x300.png" className="main-image" alt="Title" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="client-slider item ">
                                                            <div className="image-list">
                                                                <div className="content-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/Wild-Hog-Motorcycles-Mascot-Logo-300x255.png" className="main-image" alt="Title" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="client-slider item ">
                                                            <div className="image-list">
                                                                <div className="content-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/Motorcycles-UBX9PR-300x288.png" className="main-image" alt="Title" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-bd915c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bd915c9" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a41f277" data-id="a41f277" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-aa25b16 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="aa25b16" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3ede124" data-id="3ede124" data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-228db9c elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="228db9c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_32_65d83d3d9d379">
                                                                <h3 className="heading-section-subtitle  style-color">Our Team</h3>
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">MEET OUR EXPERT TEAM</h2>
                                                                </div>
                                                                <div className="heading-section-description">
                                                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                                                        Aenean commodo ligula eget dolor. Aenean massa. Cum
                                                                        sociis natoque penatibus et magnis dis parturient
                                                                        montes, nascetur ridiculus mus.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-261f32d elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="261f32d" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div
                                                data-aos="fade-right"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-4481962 elementor-invisible" data-id={4481962} data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInLeft&quot;,&quot;animation_delay&quot;:&quot;100&quot;}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-5e17567 elementor-widget elementor-widget-jkit_team" data-id="5e17567" data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_33_65d83d3da5279">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/rider-and-his-vintage-style-cafe-racer-motorcycle-in-customs-garage-2.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-0edc062 elementor-widget elementor-widget-jkit_heading" data-id="0edc062" data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_34_65d83d3da92de">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Billy Chapman</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Founder</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                data-aos="fade-up"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a559fde elementor-invisible" data-id="a559fde" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-59dc822 elementor-widget elementor-widget-jkit_team" data-id="59dc822" data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_35_65d83d3dabc12">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/man-mechanic-UYJA6P5.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-abaf4f4 elementor-widget elementor-widget-jkit_heading" data-id="abaf4f4" data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_36_65d83d3dae4ed">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Eddy Cornish</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Mechanic</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                data-aos="fade-left"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3052702 elementor-invisible" data-id={3052702} data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInRight&quot;,&quot;animation_delay&quot;:100}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-ba86208 elementor-widget elementor-widget-jkit_team" data-id="ba86208" data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_37_65d83d3db8fa0">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/young-handsome-mechanic-repairing-motorcycle-in-workshop-1111.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-07a02a1 elementor-widget elementor-widget-jkit_heading" data-id="07a02a1" data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_38_65d83d3dbae95">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Gibson Hart</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Mechanic</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-35cfb8e elementor-hidden-desktop elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="35cfb8e" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-7188351 elementor-invisible" data-id={7188351} data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;100&quot;}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-46dc260 elementor-widget elementor-widget-jkit_team" data-id="46dc260" data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_39_65d83d3dc5456">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/rider-and-his-vintage-style-cafe-racer-motorcycle-in-customs-garage-2.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-bcb27b1 elementor-widget elementor-widget-jkit_heading" data-id="bcb27b1" data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_40_65d83d3dc9fb5">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Billy Chapman</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Founder</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-2b41d7a elementor-invisible" data-id="2b41d7a" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-9276480 elementor-widget elementor-widget-jkit_team" data-id={9276480} data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_41_65d83d3dcff82">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/man-mechanic-UYJA6P5.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-0517977 elementor-widget elementor-widget-jkit_heading"
                                                        // data-id={0517977}
                                                        data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_42_65d83d3dda781">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Eddy Cornish</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Mechanic</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-756f1b6" data-id="756f1b6" data-element_type="column">
                                                <div className="elementor-widget-wrap">
                                                </div>
                                            </div>
                                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-48e02db elementor-invisible" data-id="48e02db" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;300&quot;}">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-de323f2 elementor-widget elementor-widget-jkit_team" data-id="de323f2" data-element_type="widget" data-widget_type="jkit_team.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-team style-overlay overlay-bottom jeg_module_51_43_65d83d3de69ae">
                                                                <div className="profile-card "><img decoding="async" src="wp-content/uploads/sites/174/2021/09/young-handsome-mechanic-repairing-motorcycle-in-workshop-1111.jpg" />
                                                                    <div className="hover-area alignment-center">
                                                                        <div className="profile-body">
                                                                            <h2 className="profile-title" />
                                                                            <p className="profile-designation" />
                                                                            <ul className="social-list">
                                                                                <li className="social-icon elementor-repeater-item-5ad6f6c">
                                                                                    <a href="https://facebook.com/"><i aria-hidden="true" className="fab fa-facebook-f" /></a>
                                                                                </li>
                                                                                <li className="social-icon elementor-repeater-item-a5f9af9">
                                                                                    <a href="https://twitter.com/"><i aria-hidden="true" className="fab fa-twitter" /></a></li>
                                                                                <li className="social-icon elementor-repeater-item-182327c">
                                                                                    <a href="https://instagram.com/"><i aria-hidden="true" className="jki jki-instagram-1-light" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-78c935d elementor-widget elementor-widget-jkit_heading" data-id="78c935d" data-element_type="widget" data-widget_type="jkit_heading.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_44_65d83d3e0257b">
                                                                <div className="heading-section-title  display-inline-block">
                                                                    <h2 className="heading-title">Gibson Hart</h2>
                                                                </div>
                                                                <h3 className="heading-section-subtitle  style-color">Mechanic</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-1999bba" data-id="1999bba" data-element_type="column">
                                                <div className="elementor-widget-wrap">
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section> */}


                </div>
                <Footer />

            </div>
        </div>

    )
}

export default About