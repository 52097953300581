import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { addData } from '../Servicescalls';
import toast, { Toaster } from 'react-hot-toast';
function Header() {
    const location = useLocation();
    const [show, setShow] = useState(false)
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Add smooth scrolling behavior
        });
    }

    const [services, setservices] = useState([])
    const getAllservieces = async () => {
        const resonse = await addData("getServices")
        var _data = resonse
        setservices(_data?.data?.data)
    }



    useEffect(() => {
        scrollToTop()
        getAllservieces()
    }, [])
    const [form, setform] = useState({ fullname: "", email: "", mobileNumber: "", area: "", address: "", motorcycleType: "", serviceId: "", problem: "" })

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };

    // Add function

    const addform = async (e) => {
        e.preventDefault()
        const bodydata = {
            fullname: form.fullname,
            email: form.email,
            mobileNumber: form.mobileNumber,
            area: form.area,
            address: form.address,
            motorcycleType: form.motorcycleType,
            serviceId: form.serviceId,
            problem: form.problem,
        }

        try {
            const resonse = await addData("updateForm", bodydata)
            var _data = resonse
            console.log(_data)
            setform({ fullname: "", email: "", mobileNumber: "", area: "", address: "", motorcycleType: "", serviceId: "", problem: "" })
            closeModal();
            toast.success(_data.data.message)

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const closeModal = () => {
        const modalElement = document.getElementById("exampleModal");
        const closeButton = modalElement.querySelector(".close");
        modalElement.classList.remove("show");
        modalElement.setAttribute("aria-modal", "false");
        modalElement.setAttribute("style", "display: none");
        document.body.classList.remove("modal-open");
        closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
      };


    return (
        <div>

            <header id="masthead" itemScope="itemscope" itemType="https://schema.org/WPHeader">
                <p className="main-title bhf-hidden" itemProp="headline"><a href="https://templatekit.jegtheme.com/chooper" title="Chooper – Custom Motorcycle Garage Template Kit" rel="home">Chooper – Custom
                    Motorcycle Garage Template Kit</a></p>
                <div data-elementor-type="wp-post" data-elementor-id={5} className="elementor elementor-5">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-17c6534 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="17c6534" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7836bcf" data-id="7836bcf" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d8b898a elementor-widget elementor-widget-image" data-id="d8b898a" data-element_type="widget" data-widget_type="image.default">
                                        <div className="elementor-widget-container">
                                            <style dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.17.0 - 08-11-2023 */\n\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=\".svg\"] {\n\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t" }} />
                                            <Link to="/" ><img width={266} height={155} src="wp-content/uploads/sites/174/2021/09/chooper-logo-BNGXJ52.png" className="attachment-large size-large wp-image-752" alt="" decoding="async" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-25a3f51" data-id="25a3f51" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-384dab1 elementor-widget elementor-widget-jkit_nav_menu" data-id="384dab1" data-element_type="widget" data-widget_type="jkit_nav_menu.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-nav-menu break-point-tablet submenu-click-title jeg_module_51__65d83d3cade2c" data-item-indicator="<i aria-hidden=&quot;true&quot; class=&quot;jki jki-angle-down-solid&quot;></i>">
                                                <button onClick={() => { setShow(!show) }} className="jkit-hamburger-menu"><i aria-hidden="true" className="fas fa-bars" /></button>
                                                <div className={show == true ? "jkit-menu-wrapper active" : "jkit-menu-wrapper"} >
                                                    <div className="jkit-menu-container">
                                                        <ul id="menu-menu-chooper-1" className="jkit-menu jkit-menu-direction-flex jkit-submenu-position-top">
                                                            <li id="menu-item-15" className={`menu-item menu-item-type-custom menu-item-object-custom current_page_item menu-item-home menu-item-15 ${location.pathname === "/" ? "current-menu-item" : ""}`}>
                                                                <Link to="/" >Home</Link></li>
                                                            <li id="menu-item-1211" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-1211 ${location.pathname === "/about" ? "current-menu-item" : ""}`}>
                                                                <Link to="/about">About Us</Link></li>
                                                            {/* <li id="menu-item-1212" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-1212 ${location.pathname === "/membership" ? "current-menu-item" : ""}`}>
                                                                <Link to="/membership">Membership</Link></li> */}
                                                            <li id="menu-item-1212" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-1212 ${location.pathname === "/services" ? "current-menu-item" : ""}`}>
                                                                <Link to="/services">Services</Link></li>
                                                            {/* <li id="menu-item-19" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-19">
                                                                    <a href="#">Features</a>
                                                                    <ul className="sub-menu">
                                                                        <li id="menu-item-1213" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1213">
                                                                            <a href="#">Team</a></li>
                                                                        <li id="menu-item-1214" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1214">
                                                                            <a href="#">FAQ</a></li>
                                                                        <li id="menu-item-1215" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1215">
                                                                            <a href="#">404 Page</a></li>
                                                                    </ul>
                                                                </li>
                                                                <li id="menu-item-101" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-101">
                                                                    <a href="#">Blog</a>
                                                                    <ul className="sub-menu">
                                                                        <li id="menu-item-1216" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1216">
                                                                            <a href="#">Blog</a></li>
                                                                        <li id="menu-item-1217" className="menu-item menu-item-type-post_type menu-item-object-post menu-item-1217">
                                                                            <a href="#">Single
                                                                                Post</a></li>
                                                                    </ul>
                                                                </li> */}
                                                            <li id="menu-item-1218" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-1218 ${location.pathname === "/contactus" ? "current-menu-item" : ""}`}>
                                                                <Link to="/contactus">Contact Us</Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="jkit-nav-identity-panel">
                                                        <div className="jkit-nav-site-title"><a href="https://templatekit.jegtheme.com/chooper" className="jkit-nav-logo" /></div>
                                                        <button onClick={() => { setShow(!show) }} className="jkit-close-menu"><i aria-hidden="true" className="fas fa-times" /></button>
                                                    </div>
                                                </div>
                                                <div className="jkit-overlay" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5e5fb67 elementor-hidden-tablet elementor-hidden-mobile" data-id="5e5fb67" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-3bc8474 elementor-icon-list--layout-inline elementor-align-right elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="3bc8474" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div className="elementor-widget-container">
                                            <link rel="stylesheet" href="wp-content/plugins/elementor/assets/css/widget-icon-list.min.css" />
                                            <ul className="elementor-icon-list-items elementor-inline-items">

                                                <li className="elementor-icon-list-item elementor-inline-item">
                                                    <span className="elementor-icon-list-icon">
                                                        <i aria-hidden="true" className="fa fa-mobile" />
                                                        <a href="tel:7207474594" className='phonest' >7207474594</a>
                                                    </span>
                                                    <span className="elementor-icon-list-text" />
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </header>

            <div>
                <div className="menu-container">
                    <div className="collapse-icon">
                        <span className="social_media_text" /></div>
                    <div className="menu-item">
                        <a target="_blank" href="#" style={{ padding: '0px' }}>
                            <img style={{ width: "25px" }} src="https://www.iconpacks.net/icons/2/free-youtube-logo-icon-2431-thumb.png" alt="" />
                        </a>
                    </div>
                    <div className="menu-item">
                        <a target="_blank" href="#" style={{ padding: '2px' }}>
                            <img style={{ width: "25px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/2491px-Logo_of_Twitter.svg.png" alt="" />
                        </a>
                    </div>
                    <div className="menu-item mb-2">
                        <a target="_blank" href="#" style={{ padding: '0px' }}>
                            <img style={{ width: "25px", height: "21px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/2048px-2021_Facebook_icon.svg.png" alt="" />
                        </a>
                    </div>
                    <div className="menu-item mb-2">
                        <a target="_blank" href="#" style={{ padding: '0px' }}>
                            <img style={{ width: "25px", height: "21px" }} src="https://static-00.iconduck.com/assets.00/instagram-icon-2048x2048-uc6feurl.png" alt="" />
                        </a>
                    </div>

                </div>

                <div className="menu-container1">
                    <div className="collapse-icon">
                        <span className="social_media_text" /></div>
                    <div className="menu-item">
                        <a target="_blank" href="https://wa.me/7207474594" >
                            <div className='rounded-circle bg-success p-2'>
                                <i style={{ fontSize: "40px" }} className='fa fa-whatsapp text-white' />
                            </div>
                            {/* <img style={{ width: "25px" }} src="https://www.iconpacks.net/icons/2/free-youtube-logo-icon-2431-thumb.png" alt="" /> */}
                        </a>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-dark" id="exampleModalLabel">Request an estimate</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="wpb_wrapper"><div role="form" className="wpcf7" id="wpcf7-f510-p1296-o1" lang="en-US" dir="ltr">
                                <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true" /> <ul /></div>
                                <form onSubmit={(e)=>{addform(e)}} className="wpcf7-form init" data-status="init">
                                    <div className="form-wrapper">
                                        <div className="row">
                                            <div className="col-md-6">Full name <span className="wpcf7-form-control-wrap" data-name="fullname">
                                                <input required type="text"  name="fullname" value={form.fullname} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Enter your full name" /></span></div>
                                            <div className="col-md-6">Your phone <span className="wpcf7-form-control-wrap" data-name="mobileNumber">
                                                <input required type="text" pattern="[0-9]{10}"  maxLength={10} name="mobileNumber" value={form.mobileNumber} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your phone" /></span></div>
                                            <div className="col-md-6">Your email <span className="wpcf7-form-control-wrap" data-name="email">
                                                <input required type="email" name="email" value={form.email} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" placeholder="Your email" /></span></div>

                                            <div className="col-md-6">Your Area <span className="wpcf7-form-control-wrap" data-name="area">
                                                <input required type="text" name="area" value={form.area} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Enter your area name" /></span></div>
                                            <div className="col-md-6">Your address <span className="wpcf7-form-control-wrap" data-name="address">
                                                <input required type="text" name="address" value={form.address} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your address" /></span></div>
                                            <div className="col-md-6">Motorcycle Type <span className="wpcf7-form-control-wrap" data-name="motorcycleType">
                                                <select required name="motorcycleType" value={form.motorcycleType} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-select select2-hidden-accessible" tabIndex={-1} aria-hidden="true"><option value="Standard">Standard</option><option value="Cruiser">Cruiser</option><option value="Touring">Touring</option><option value="Sport">Sport</option><option value="Scooters">Scooters</option></select><span className="select2 select2-container select2-container--default select2-container--above" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-motorcycle-type-wx-container"><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">Services <span className="wpcf7-form-control-wrap" data-name="serviceId">
                                                <select required name="serviceId" value={form.serviceId} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-select select2-hidden-accessible" tabIndex={-1} aria-hidden="true">
                                                    <option value="">Select Services</option>
                                                    {services.map((data, index) => (
                                                        <option key={index} value={data._id}>{data.title}</option>
                                                    ))}

                                                </select>
                                                <span className="select2 select2-container select2-container--default select2-container--above" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-services-uw-container"><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div>
                                            {/* <div className="col-md-6">Your buget <span className="wpcf7-form-control-wrap" data-name="your-budget"><select name="your-budget" className="wpcf7-form-control wpcf7-select select2-hidden-accessible"  tabIndex={-1} aria-hidden="true"><option value="1000$">1000$</option><option value="2000$">2000$</option><option value="5000$">5000$</option><option value="10000$">10000$</option></select><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-your-budget-sp-container"><span className="select2-selection__rendered" id="select2-your-budget-sp-container" title="1000$">1000$</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">Problem <span className="wpcf7-form-control-wrap" data-name="problem">
                                                <textarea required type="text" name="problem" value={form.problem} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your Problem" /></span></div>
                                        </div>
                                    </div>
                                    <div className="form-button button-effect1 theme-bg">
                                        <input type="submit" className="wpcf7-form-control has-spinner wpcf7-submit" /><span className="wpcf7-spinner" /><span className="icon-effect" /> <span className="note">Your information will be safe</span></div>
                                    <div className="wpcf7-response-output" aria-hidden="true" />
                                </form>
                            </div>
                            </div>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Toaster />
        </div>

    )
}

export default Header