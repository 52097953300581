import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Commonservice from './Commonservice'


function Services() {
    return (
        <div >
            <div id="page" className="hfeed site">
                <Header />
                <div data-elementor-type="wp-page" data-elementor-id={51} className="elementor elementor-51">
                    <section style={{
                        backgroundImage: `linear-gradient(#0000005e, #000000a6), url("https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/unsplash-qQ2o7JXiCLc.jpg")`, padding: "150px 100px 100px 100px"
                    }}
                        className="text-center elementor-section elementor-top-section elementor-element elementor-element-87313cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="87313cf" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-container elementor-column-gap-default">
                            <div style={{ width: "100%" }} className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9181f1e" data-id="9181f1e" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d85c4d7 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="d85c4d7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-e2a12ad elementor-widget elementor-widget-heading" data-id="e2a12ad" data-element_type="widget" data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 style={{ fontSize: "50px", color: "#fff" }} className="elementor-heading-title elementor-size-default">SERVICES</h3>
                                                    </div>
                                                </div>
                                                <div style={{ display: "none" }} className="text-center elementor-element elementor-element-c7be932 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="c7be932" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="icon-list.default">
                                                    <div className="elementor-widget-container">
                                                        <ul className="elementor-icon-list-items elementor-inline-items">
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-text">HOME</span>
                                                            </li>
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-icon">
                                                                    <i aria-hidden="true" className="jki jki-slash-solid" /> </span>
                                                                <span className="elementor-icon-list-text">
                                                                    SERVICES</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>

                    <section style={{ background: "#fff", paddingTop: "50px" }} className="elementor-section elementor-top-section elementor-element elementor-element-d4f5295 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4f5295" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div style={{ background: "#fff" }} className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-edb1f35" data-id="edb1f35" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-9d36a98 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="9d36a98" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-heading  align-left align-tablet- align-mobile- jeg_module_51_13_65d83d3d34a8d">
                                                <h3 className="heading-section-subtitle  style-color">
                                                    SERVICES</h3>
                                                <div className="heading-section-title  display-inline-block">
                                                    <h2 style={{ color: "#000" }} className="heading-title">Choose your plan</h2>
                                                </div>
                                                <div style={{ color: "#000" }} className="heading-section-description">
                                                    <p >
                                                        With our wide service network, we offer premium and smart maintenance, discounts and special offers, priority roadside assistance, and the flexibility to choose service according to your convenience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Commonservice />

                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="elementor-section elementor-top-section elementor-element elementor-element-3e2dfac elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3e2dfac" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-20e6371" data-id="20e6371" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-a8134bd elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a8134bd" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div
                                                data-aos="fade-right"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5525613" data-id={5525613} data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-f853a20 jkit-equal-height-disable elementor-invisible elementor-widget elementor-widget-jkit_icon_box" data-id="f853a20" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="jkit_icon_box.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-icon-box icon-position-left elementor-animation-float jeg_module_51_4_65d83d3d0ae4f">
                                                                <div className="jkit-icon-box-wrapper hover-from-left">
                                                                    <div className="icon-box icon-box-header elementor-animation-grow">
                                                                        <div className="icon style-color"><i aria-hidden="true" className="jki jki-user-cog-solid" /></div>
                                                                    </div>
                                                                    <div className="icon-box icon-box-body">
                                                                        <p className="title">Pro Team</p>
                                                                        <p className="icon-box-description">
                                                                            Your vehicle is serviced or major service done at your nearest Bikers24 Prime Service Centre or at your doorstep.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                data-aos="fade-up"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c374b8d" data-id="c374b8d" data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-3cfbd60 jkit-equal-height-disable elementor-invisible elementor-widget elementor-widget-jkit_icon_box" data-id="3cfbd60" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="jkit_icon_box.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-icon-box icon-position-left elementor-animation-float jeg_module_51_5_65d83d3d0d2b4">
                                                                <div className="jkit-icon-box-wrapper hover-from-left">
                                                                    <div className="icon-box icon-box-header elementor-animation-grow">
                                                                        <div className="icon style-color"><i aria-hidden="true" className="jki jki-award-solid" /></div>
                                                                    </div>
                                                                    <div className="icon-box icon-box-body">
                                                                        <p className="title">Best Awards</p>
                                                                        <p className="icon-box-description">
                                                                            Your vehicle is serviced or major service done at your nearest Bikers24 Prime Service Centre or at your doorstep.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                data-aos="fade-left"
                                                data-aos-offset="300"
                                                data-aos-easing="ease-in-sine"
                                                className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7dde910" data-id="7dde910" data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-fb18857 jkit-equal-height-disable elementor-invisible elementor-widget elementor-widget-jkit_icon_box" data-id="fb18857" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="jkit_icon_box.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-icon-box icon-position-left elementor-animation-float jeg_module_51_6_65d83d3d0fd19">
                                                                <div className="jkit-icon-box-wrapper hover-from-bottom">
                                                                    <div className="icon-box icon-box-header elementor-animation-grow">
                                                                        <div className="icon style-color"><i aria-hidden="true" className="jki jki-motorcycle-solid" /></div>
                                                                    </div>
                                                                    <div className="icon-box icon-box-body">
                                                                        <p className="title">Best Design</p>
                                                                        <p className="icon-box-description">
                                                                            Your vehicle is serviced or major service done at your nearest Bikers24 Prime Service Centre or at your doorstep.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer />

            </div>
        </div>

    )
}

export default Services