import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Slider from "react-slick";
import { addData } from '../Servicescalls';
import toast, { Toaster } from 'react-hot-toast';
function Contactus() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [services, setservices] = useState([])
    const getAllservieces = async () => {
        const resonse = await addData("getServices")
        var _data = resonse
        setservices(_data?.data?.data)
    }

    useEffect(() => {
        getAllservieces()
    }, [])
    const [form, setform] = useState({ fullname: "", email: "", mobileNumber: "", area: "", address: "", motorcycleType: "", serviceId: "", problem: "" })

    const handleChange = (e) => {
        let myUser = { ...form };
        myUser[e.target.name] = e.target.value;
        setform(myUser);
    };

    // Add function

    const addform = async (e) => {
        e.preventDefault()
        const bodydata = {
            fullname: form.fullname,
            email: form.email,
            mobileNumber: form.mobileNumber,
            area: form.area,
            address: form.address,
            motorcycleType: form.motorcycleType,
            serviceId: form.serviceId,
            problem: form.problem,
        }

        try {
            const resonse = await addData("updateForm", bodydata)
            var _data = resonse
            console.log(_data)
            setform({ fullname: "", email: "", mobileNumber: "", area: "", address: "", motorcycleType: "", serviceId: "", problem: "" })
            toast.success(_data.data.message)

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }




    return (
        <div >
            <div id="page" className="hfeed site">
                <Header />
                <div data-elementor-type="wp-page" data-elementor-id={51} className="elementor elementor-51">
                    <section style={{
                        backgroundImage: `linear-gradient(#0000005e, #000000a6), url("https://templatekit.jegtheme.com/chooper/wp-content/uploads/sites/174/2021/09/unsplash-qQ2o7JXiCLc.jpg")`, padding: "150px 100px 100px 100px"
                    }}
                        className="text-center elementor-section elementor-top-section elementor-element elementor-element-87313cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="87313cf" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div className="elementor-container elementor-column-gap-default">
                            <div style={{ width: "100%" }} className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9181f1e" data-id="9181f1e" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-d85c4d7 elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="d85c4d7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-e2a12ad elementor-widget elementor-widget-heading" data-id="e2a12ad" data-element_type="widget" data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h3 style={{ fontSize: "50px", color: "#fff" }} className="elementor-heading-title elementor-size-default">CONTACT US</h3>
                                                    </div>
                                                </div>
                                                <div style={{ display: "none" }} className="elementor-element elementor-element-c7be932 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="c7be932" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="icon-list.default">
                                                    <div className="elementor-widget-container">
                                                        <ul className="elementor-icon-list-items elementor-inline-items">
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-text">HOME</span>
                                                            </li>
                                                            <li className="elementor-icon-list-item elementor-inline-item">
                                                                <span className="elementor-icon-list-icon">
                                                                    <i aria-hidden="true" className="jki jki-slash-solid" /> </span>
                                                                <span className="elementor-icon-list-text">CONTACT US</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>


                    <section style={{ background: "#fff" }} className="elementor-section elementor-top-section elementor-element elementor-element-3e2dfac elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3e2dfac" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div style={{ background: "#fff" }} className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default row">

                            <div className="elementor-column  col-md-4 elementor-top-column elementor-element elementor-element-20e6371" data-id="20e6371" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">

                                    <div className="elementor-column  elementor-inner-column elementor-element elementor-element-465d6b6" data-id="465d6b6" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">

                                            <div className="mt-5 pt-5 elementor-element elementor-element-ba06284 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="ba06284" data-element_type="widget" data-widget_type="icon-list.default">
                                                <div className="elementor-widget-container">
                                                    <ul className="elementor-icon-list-items">
                                                        <li style={{ fontSize: "20px", padding: "10px" }} className="elementor-icon-list-item ">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-map-marker-alt" /> </span>
                                                            <span style={{ color: "#000" }} className="elementor-icon-list-text">
                                                                Sarath City Capital Mall Road lane 3, Gachibowli Kondapur, Hyderabad 500084                                                                </span>
                                                        </li>
                                                        <li style={{ fontSize: "20px", padding: "10px" }} className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-envelope" /> </span>
                                                            <span style={{ color: "#000" }} className="elementor-icon-list-text">contact@bikers24.com</span>
                                                        </li>
                                                        <li style={{ fontSize: "20px", padding: "10px" }} className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fa fa-mobile" /> </span>
                                                            <span style={{ color: "#000" }} className="elementor-icon-list-text">7207474594</span>
                                                        </li>
                                                        <li style={{ fontSize: "20px", padding: "10px" }} className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-clock" />
                                                            </span>
                                                            <span style={{ color: "#000" }} className="elementor-icon-list-text">Opening :
                                                                Sun-Sat 09:00 AM - 09:00 PM</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: '500px', height: '350px' }}>
                                                <div id="google-maps-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
                                                    <iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0}
                                                     src="https://www.google.com/maps/embed/v1/place?q=Sarath+City+Capital+Mall+Road+lane+3,+Gachibowli+Kondapur,+Hyderabad+500084&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" />
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="elementor-column  col-md-8 elementor-top-column elementor-element elementor-element-20e6371" data-id="20e6371" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-a8134bd elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a8134bd" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-no row">

                                            <div className="wpb_wrapper"><div role="form" className="wpcf7" id="wpcf7-f510-p1296-o1" lang="en-US" dir="ltr">
                                                <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true" /> <ul /></div>
                                                <form onSubmit={(e) => { addform(e) }} className="wpcf7-form init" data-status="init">
                                                    <div className="form-wrapper">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="title">
                                                                    <h4>Request an estimate</h4>
                                                                    <h3>OUR SERVICES</h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">Full name <span className="wpcf7-form-control-wrap" data-name="fullname">
                                                                <input required type="text" name="fullname" value={form.fullname} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Enter your full name" /></span></div>
                                                            <div className="col-md-6">Your phone <span className="wpcf7-form-control-wrap" data-name="mobileNumber">
                                                                <input required type="text" pattern="[0-9]{10}" maxLength={10} name="mobileNumber" value={form.mobileNumber} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your phone" /></span></div>
                                                            <div className="col-md-6">Your email <span className="wpcf7-form-control-wrap" data-name="email">
                                                                <input required type="email" name="email" value={form.email} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" placeholder="Your email" /></span></div>

                                                            <div className="col-md-6">Your Area <span className="wpcf7-form-control-wrap" data-name="area">
                                                                <input required type="text" name="area" value={form.area} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Enter your area name" /></span></div>
                                                            <div className="col-md-6">Your address <span className="wpcf7-form-control-wrap" data-name="address">
                                                                <input required type="text" name="address" value={form.address} onChange={(e) => { handleChange(e) }} size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your address" /></span></div>
                                                            <div className="col-md-6">Motorcycle Type <span className="wpcf7-form-control-wrap" data-name="motorcycleType">
                                                                <select required name="motorcycleType" value={form.motorcycleType} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-select select2-hidden-accessible" tabIndex={-1} aria-hidden="true"><option value="Standard">Standard</option><option value="Cruiser">Cruiser</option><option value="Touring">Touring</option><option value="Sport">Sport</option><option value="Scooters">Scooters</option></select><span className="select2 select2-container select2-container--default select2-container--above" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-motorcycle-type-wx-container"><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">Services <span className="wpcf7-form-control-wrap" data-name="serviceId">
                                                                <select required name="serviceId" value={form.serviceId} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-select select2-hidden-accessible" tabIndex={-1} aria-hidden="true">
                                                                    <option value="">Select Services</option>
                                                                    {services.map((data, index) => (
                                                                        <option key={index} value={data._id}>{data.title}</option>
                                                                    ))}

                                                                </select>
                                                                <span className="select2 select2-container select2-container--default select2-container--above" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-services-uw-container"><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div>
                                                            {/* <div className="col-md-6">Your buget <span className="wpcf7-form-control-wrap" data-name="your-budget"><select name="your-budget" className="wpcf7-form-control wpcf7-select select2-hidden-accessible"  tabIndex={-1} aria-hidden="true"><option value="1000$">1000$</option><option value="2000$">2000$</option><option value="5000$">5000$</option><option value="10000$">10000$</option></select><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" style={{ width: '233px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-labelledby="select2-your-budget-sp-container"><span className="select2-selection__rendered" id="select2-your-budget-sp-container" title="1000$">1000$</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span></span></div> */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">Problem <span className="wpcf7-form-control-wrap" data-name="problem">
                                                                <textarea required type="text" name="problem" value={form.problem} onChange={(e) => { handleChange(e) }} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Your Problem" /></span></div>
                                                        </div>

                                                    </div>
                                                    <div className="form-button button-effect1 theme-bg">
                                                        <input type="submit" className="wpcf7-form-control has-spinner wpcf7-submit" /><span className="wpcf7-spinner" /><span className="icon-effect" /> <span className="note">Your information will be safe</span></div>
                                                    <div className="wpcf7-response-output" aria-hidden="true" />
                                                </form>
                                            </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="elementor-section elementor-top-section elementor-element elementor-element-734c5e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="734c5e0" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5eeb4f2" data-id="5eeb4f2" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-dafcf8c elementor-invisible elementor-widget elementor-widget-jkit_heading" data-id="dafcf8c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="jkit_heading.default">
                                        <div className="elementor-widget-container">
                                            <div className="jeg-elementor-kit jkit-heading  align-center align-tablet- align-mobile- jeg_module_51_26_65d83d3d84254">
                                                {/* <h3 className="heading-section-subtitle  style-color">Testimonials</h3> */}
                                                <div className="heading-section-title  display-inline-block">
                                                    <h2 className="heading-title">WHAT ARE CLIENTS SAY</h2>
                                                </div>
                                                <div className="heading-section-description">
                                                    <p>
                                                        Get your motorcycle repair at two wheeler srvice center done at the nearest Bikers24 garage or at your doorstep and we are the best bike repair shop near you.
                                                        We are expert of any kinds of two wheeler even if it’s Royal Enfield. Best Two Wheeler Servicing Near Me.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-46bff6d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="46bff6d" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;}">
                                        <div className="elementor-container elementor-column-gap-no">
                                            <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3705485" data-id={3705485} data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-fec8848 elementor-widget elementor-widget-jkit_testimonials" data-id="fec8848" data-element_type="widget" data-widget_type="jkit_testimonials.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="jeg-elementor-kit jkit-testimonials arrow-bottom-middle style-2 quote-override jeg_module_51_27_65d83d3d8d3db" data-id="jeg_module_51_27_65d83d3d8d3db" data-settings="{&quot;autoplay&quot;:true,&quot;autoplay_speed&quot;:5000,&quot;autoplay_hover_pause&quot;:false,&quot;show_navigation&quot;:false,&quot;navigation_left&quot;:&quot;<i aria-hidden=\&quot;true\&quot; class=\&quot;fas fa-angle-left\&quot;><\/i>&quot;,&quot;navigation_right&quot;:&quot;<i aria-hidden=\&quot;true\&quot; class=\&quot;fas fa-angle-right\&quot;><\/i>&quot;,&quot;show_dots&quot;:false,&quot;arrow_position&quot;:&quot;bottom&quot;,&quot;responsive&quot;:{&quot;desktop&quot;:{&quot;items&quot;:3,&quot;margin&quot;:20,&quot;breakpoint&quot;:1025},&quot;tablet&quot;:{&quot;items&quot;:2,&quot;margin&quot;:10,&quot;breakpoint&quot;:768},&quot;mobile&quot;:{&quot;items&quot;:1,&quot;margin&quot;:10,&quot;breakpoint&quot;:0}}}">
                                                                <div className="testimonials-list">
                                                                    <Slider {...settings}>
                                                                        <div className="testimonials-track">
                                                                            <div className="testimonial-item p-2  elementor-repeater-item-697e6c9">
                                                                                <div className="testimonial-box hover-from-left">
                                                                                    <div className="icon-content"><i aria-hidden="true" className="jki jki-quote1-light" /></div>
                                                                                    <div className="comment-content">
                                                                                        <p>                                                                                        We are always concerned about the rebirth of your Bike or scooty, using all the best quality products and vehicle servicing equipment that are available at our service centers. To find us search best bike service near me .
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="comment-header">
                                                                                        <ul className="rating-stars">
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="comment-bio">
                                                                                        <div className="bio-details">
                                                                                            <div className="profile-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/portrait-of-handsome-smiling-young-african-man-over-a-pink-brick-wall-e1632793966174-150x150.jpg" alt="John Doe" /></div>
                                                                                            <span className="profile-info">
                                                                                                <strong className="profile-name">John
                                                                                                    Doe</strong>
                                                                                                <p className="profile-des">Customer</p>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="testimonials-track">
                                                                            <div className="testimonial-item p-2  elementor-repeater-item-16efe40">
                                                                                <div className="testimonial-box hover-from-left">
                                                                                    <div className="icon-content"><i aria-hidden="true" className="jki jki-quote1-light" /></div>
                                                                                    <div className="comment-content">
                                                                                        <p>
                                                                                            We are always concerned about the rebirth of your Bike or scooty, using all the best quality products and vehicle servicing equipment that are available at our service centers. To find us search best bike service near me .

                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="comment-header">
                                                                                        <ul className="rating-stars">
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-half" />
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="comment-bio">
                                                                                        <div className="bio-details">
                                                                                            <div className="profile-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/happy-man-or-waiter-at-bar-or-coffee-shop-e1632794143143-150x150.jpg" alt="Theo Jota" /></div>
                                                                                            <span className="profile-info">
                                                                                                <strong className="profile-name">Theo
                                                                                                    Jota</strong>
                                                                                                <p className="profile-des">Customer</p>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="testimonials-track">
                                                                            <div className="testimonial-item p-2  elementor-repeater-item-adaad2c">
                                                                                <div className="testimonial-box hover-from-left">
                                                                                    <div className="icon-content"><i aria-hidden="true" className="jki jki-quote1-light" /></div>
                                                                                    <div className="comment-content">
                                                                                        <p>
                                                                                            We are always concerned about the rebirth of your Bike or scooty, using all the best quality products and vehicle servicing equipment that are available at our service centers. To find us search best bike service near me .

                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="comment-header">
                                                                                        <ul className="rating-stars">
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-half" />
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="comment-bio">
                                                                                        <div className="bio-details">
                                                                                            <div className="profile-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/young-woman-with-fair-hair-holding-milk-from-glass-bottle-with-smile-at-home--e1632798252884-150x150.jpg" alt="Diodra R." /></div>
                                                                                            <span className="profile-info">
                                                                                                <strong className="profile-name">Diodra
                                                                                                    R.</strong>
                                                                                                <p className="profile-des">Customer</p>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="testimonials-track">
                                                                            <div className="testimonial-item  p-2 elementor-repeater-item-de6bed8">
                                                                                <div className="testimonial-box hover-from-left">
                                                                                    <div className="icon-content"><i aria-hidden="true" className="jki jki-quote1-light" /></div>
                                                                                    <div className="comment-content">
                                                                                        <p>
                                                                                            We are always concerned about the rebirth of your Bike or scooty, using all the best quality products and vehicle servicing equipment that are available at our service centers. To find us search best bike service near me .

                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="comment-header">
                                                                                        <ul className="rating-stars">
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-solid" />
                                                                                            </li>
                                                                                            <li><i aria-hidden="true" className="jki jki-star-half" />
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="comment-bio">
                                                                                        <div className="bio-details">
                                                                                            <div className="profile-image"><img decoding="async" src="wp-content/uploads/sites/174/2021/09/fashionable-male-motorcycle-driver-poses-on-black-motorbike-rides-in-beautiful-yellow-park-e1632798507677-150x150.jpg" alt="George Baker" /></div>
                                                                                            <span className="profile-info">
                                                                                                <strong className="profile-name">George
                                                                                                    Baker</strong>
                                                                                                <p className="profile-des">Customer</p>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Slider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>
                <Footer />
                <Toaster />
            </div>
        </div>

    )
}

export default Contactus