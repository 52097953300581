import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Components/About';
import Services from './Components/Services';
import Plans from './Components/Plans';
import Contactus from './Components/Contactus';
import '../src/about.css'
import PRIVACYPOLICY from './Components/Privacypolicy';
import Refund from './Components/Refundpolicy';
import Termsandconditions from './Components/Termsandconditioins';

function App() {
  return (
<BrowserRouter>
<Routes>
  <Route path='/' element={ <Home />} />
  <Route path='/about' element={ <About />} />
  <Route path='/services' element={ <Plans />} />
  <Route path='/membership' element={ <Plans />} />
  <Route path='/contactus' element={ <Contactus />} />
  <Route path='/privacypolicy' element={ <PRIVACYPOLICY />} />
  <Route path='/refundpolicy' element={ <Refund />} />
  <Route path='/termsandconditions' element={ <Termsandconditions />} />
</Routes>
</BrowserRouter>
  
  );
}

export default App;
